/* API CONSTANTS */
export const API_BASE_URL = 'https://www.uniquebrother.com/';
export const ADMIN_SPREE_TOKEN = process.env.REACT_APP_ADMIN_SPREE_TOKEN;

/* ENV variables*/
export const APP_TITLE = process.env.REACT_APP_APP_TITLE || 'Unique Brother';

/* Account */
export const INDEX_ACCOUNT_CARDS = 'INDEX_ACCOUNT_CARDS';
export const INDEX_DISTRIBUTOR_CARDS = 'INDEX_DISTRIBUTOR_CARDS';
export const CREATE_ACCOUNT_PAYMENT_METHOD = 'CREATE_ACCOUNT_PAYMENT_METHOD';

/* Category constants */
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const FETCH_ALL_CATEGORIES = 'FETCH_ALL_CATEGORIES';

/* Product constants */
export const FETCH_ALL_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_ALL_STORE_PRODUCTS = 'FETCH_ALL_STORE_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

/* Unit Of Measurement constants */
export const CREATE_UNIT_OF_MEASUREMENT = 'ADD_UNIT_OF_MEASUREMENT';
export const FETCH_ALL_UNIT_OF_MEASUREMENTS = 'FETCH_ALL_UNIT_OF_MEASUREMENTS';

/* Alert constants */
export const CREATE_FLASH_MESSAGE = 'CREATE_FLASH_MESSAGE';
export const FETCH_ALL_FLASH_MESSAGES = 'FETCH_ALL_FLASH_MESSAGES';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';

/* Menu Item constants */
export const FETCH_ALL_STORE_SIDEMENU_ITEMS = 'FETCH_ALL_STORE_SIDEMENU_ITEMS';

/* Right Sidebar Constants */
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';

/* Left Sidebar Constants */
export const TOGGLE_LEFT_SIDEBAR = 'TOGGLE_LEFT_SIDEBAR';

/* User constants */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const REMOVE_USER_STORE_CREDITS = 'REMOVE_USER_STORE_CREDITS';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';

/* Candidate constants */
export const UPLOAD_OFFICIAL_ID = 'UPLOAD_OFFICIAL_ID';
export const UPDATE_REGISTER_DOCUMENTS = 'UPDATE_REGISTER_DOCUMENTS';
export const UPDATE_REGISTER_DOCUMENTS_STATUS = 'UPDATE_REGISTER_DOCUMENTS_STATUS';
export const UPDATE_CURP = 'UPDATE_CURP';

/* Distributor Constants*/
export const CREATE_DISTRIBUTOR_CLIENT = 'CREATE_DISTRIBUTOR_CLIENT';
export const CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT = 'CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT';
export const INDEX_DISTRIBUTOR_CLIENT = 'INDEX_DISTRIBUTOR_CLIENT';
export const UPDATE_DISTRIBUTOR_CLIENT = 'UPDATE_DISTRIBUTOR_CLIENT';
export const DELETE_DISTRIBUTOR_CLIENT = 'DELETE_DISTRIBUTOR_CLIENT';

/* Distributor Money*/
export const INDEX_DISTRIBUTOR_MONEY = 'INDEX_DISTRIBUTOR_MONEY';
export const PAY_DISTRIBUTOR_COMISSION_LIST = 'PAY_DISTRIBUTOR_COMISSION_LIST';
export const INDEX_DISTRIBUTOR_COMISSION = 'INDEX_DISTRIBUTOR_COMISSION';
export const UPDATE_DISTRIBUTOR_COMISSION = 'UPDATE_DISTRIBUTOR_COMISSION';
export const DELETE_DISTRIBUTOR_COMISSION = 'DELETE_DISTRIBUTOR_COMISSION';

/* Distributor Order */
export const INDEX_DISTRIBUTOR_ORDER = 'INDEX_DISTRIBUTOR_ORDER';

/* Distributor Network */
export const INDEX_DISTRIBUTOR_NETWORK = 'INDEX_DISTRIBUTOR_NETWORK';
export const CREATE_DISTRIBUTOR_NETWORK = 'CREATE_DISTRIBUTOR_NETWORK';

/* Distributor Payments */
export const INDEX_DISTRIBUTOR_SOURCES = 'INDEX_DISTRIBUTOR_SOURCES';
export const CREATE_DISTRIBUTOR_SOURCE = 'CREATE_DISTRIBUTOR_SOURCE';
export const DELETE_DISTRIBUTOR_SOURCE = 'DELETE_DISTRIBUTOR_SOURCE';
export const CREATE_CREDIT_CARD_PAYMENT = 'CREATE_CREDIT_CARD_PAYMENT';

export const CREATE_DISTRIBUTOR_PAYMENT = 'CREATE_DISTRIBUTOR_PAYMENT';
export const UPDATE_DISTRIBUTOR_PAYMENT = 'UPDATE_DISTRIBUTOR_PAYMENT';
export const UPDATE_DEFAULT_CREDIT_CARD = 'UPDATE_DEFAULT_CREDIT_CARD';
export const CLEAR_CREDIT_CARDS = 'CLEAR_CREDIT_CARDS';

export const INDEX_DISTRIBUTOR_ADDRESSES = 'INDEX_DISTRIBUTOR_ADDRESSES';
export const CREATE_DISTRIBUTOR_ADDRESS = 'CREATE_DISTRIBUTOR_ADDRESS';
export const DELETE_DISTRIBUTOR_ADDRESS = 'DELETE_DISTRIBUTOR_ADDRESS';
export const UPDATE_DISTRIBUTOR_ADDRESS = 'UPDATE_DISTRIBUTOR_ADDRESS';

/* ADMIN Candidates*/
export const INDEX_ADMIN_CANDIDATE = 'INDEX_ADMIN_CANDIDATE';
export const REGISTER_DOCUMENT_ADMIN_CANDIDATE = 'REGISTER_DOCUMENT_ADMIN_CANDIDATE';
export const CLEAR_REGISTER_DOCUMENT_ADMIN_CANDIDATE = 'CLEAR_REGISTER_DOCUMENT_ADMIN_CANDIDATE';
export const ADMIN_UPDATE_CANDIDATE = 'ADMIN_UPDATE_CANDIDATE';
export const ADMIN_ACCEPT_CANDIDATE = 'ADMIN_ACCEPT_CANDIDATE';
export const ADMIN_REJECT_CANDIDATE = 'ADMIN_REJECT_CANDIDATE';
export const INDEX_ADMIN_DISTRIBUTOR = 'INDEX_ADMIN_DISTRIBUTOR';

/* Admin Distributors */
export const UPDATE_ADMIN_DISTRIBUTOR = 'UPDATE_ADMIN_DISTRIBUTOR';

/* COUNTRIES */
export const INDEX_COUNTRIES = 'INDEX_COUNTRIES';
export const CREATE_COUNTRIES = 'CREATE_COUNTRIES';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';

/* SUBSCRIPTIONS */
export const INDEX_SUBSCRIPTION_PLANS = 'INDEX_SUBSCRIPTION_PLANS';
export const SUBSCRIBE_USER_TO_PLAN = 'SUBSCRIBE_USER_TO_PLAN';
export const CANCEL_USER_PLAN_SUBSCRIPTION = 'CANCEL_USER_PLAN_SUBSCRIPTION';
export const UPDATE_USER_SUBSCRIPTIONS = 'UPDATE_USER_SUBSCRIPTIONS';
export const UPDATE_USER_MEMBERSHIP = 'UPDATE_USER_MEMBERSHIP';
export const ADD_USER_MEMBERSHIP = 'ADD_USER_MEMBERSHIP';

/* MULTI LEVEL */
export const SHOW_MULTI_LEVEL = 'SHOW_MULTI_LEVEL';

/* BANK ACCOUNT */
export const SHOW_BANK_ACCOUNT = 'SHOW_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const UPDATE_STORE_CREDIT = 'UPDATE_STORE_CREDIT';

/* ORDERS */
export const ADD_ORDER = 'ADD_ORDER';
export const INDEX_ORDER = 'INDEX_ORDER';

/* RANK */
export const ADD_RANK = 'ADD_RANK';
export const ADD_RANK_LEVEL = 'ADD_RANK_LEVEL';
export const UPDATE_RANK = 'UPDATE_RANK';

/* PAYMENT PLANS*/
export const ADD_PAYMENT_PLAN = 'ADD_PAYMENT_PLAN';
export const ADD_PAYMENT_PLANS_LIST = 'ADD_PAYMENT_PLANS_LIST';
export const DELETE_PAYMENT_PLAN = 'DELETE_PAYMENT_PLAN';

/* COMISSIONS */
export const ADD_COMISSION = 'ADD_COMISSION';
export const GET_COMISSIONS = 'GET_COMISSIONS';
export const GET_COMMISSION_RECORDS = 'GET_COMMISSION_RECORDS';
export const GET_COMMISSION_PAYMENTS_LEVEL_1 = 'GET_COMMISSION_PAYMENTS_LEVEL_1';
export const GET_COMMISSION_PAYMENTS_LEVEL_2 = 'GET_COMMISSION_PAYMENTS_LEVEL_2';
export const DELETE_COMISSION = 'DELETE_COMISSION';
export const UPDATE_COMISSION = 'UPDATE_COMISSION';

/* REPORTS */
export const GET_CUBIT_ORDERS = 'GET_CUBIT_ORDERS';
export const GET_CUBIT_LINE_ITEMS = 'GET_CUBIT_LINE_ITEMS';
export const GET_CUBIT_ITEMS_CLIENTS = 'GET_CUBIT_ITEMS_CLIENTS';

/* SCHEDULED PAYMENTS */
export const ADD_SCHEDULED_PAYMENT = 'ADD_SCHEDULED_PAYMENT';
export const GET_SCHEDULED_PAYMENTS = 'GET_SCHEDULED_PAYMENTS';
export const DELETE_SCHEDULED_PAYMENT = 'DELETE_SCHEDULED_PAYMENT';
export const UPDATE_SCHEDULED_PAYMENT = 'UPDATE_SCHEDULED_PAYMENT';

/* STATEMENT ACCOUNT */
export const GET_STATEMENT_ACCOUNT = 'GET_STATEMENT_ACCOUNT';

/* POS PRODUCTS */
export const POS_SEARCH_PRODUCT = {
    loading: 'POS/SEARCH_PRODUCT/LOADING',
    success: 'POS/SEARCH_PRODUCT/SUCCESS',
    error: 'POS/SEARCH_PRODUCT/ERROR',
};

export const POS_SELECT_PRODUCT = {
    loading: 'POS/SELECT_PRODUCT/LOADING',
    success: 'POS/SELECT_PRODUCT/SUCCESS',
    error: 'POS/SELECT_PRODUCT/ERROR',
};

export const POS_UNSELECT_PRODUCT = {
    loading: 'POS/UNSELECT_PRODUCT/LOADING',
    success: 'POS/UNSELECT_PRODUCT/SUCCESS',
    error: 'POS/UNSELECT_PRODUCT/ERROR',
};

/* POS VARIANTS */
export const POS_SEARCH_VARIANT = {
    loading: 'POS/SEARCH_VARIANT/LOADING',
    success: 'POS/SEARCH_VARIANT/SUCCESS',
    error: 'POS/SEARCH_VARIANT/ERROR',
};

export const POS_SELECT_VARIANT = {
    loading: 'POS/SELECT_VARIANT/LOADING',
    success: 'POS/SELECT_VARIANT/SUCCESS',
    error: 'POS/SELECT_VARIANT/ERROR',
};

export const POS_UNSELECT_VARIANT = {
    loading: 'POS/UNSELECT_VARIANT/LOADING',
    success: 'POS/UNSELECT_VARIANT/SUCCESS',
    error: 'POS/UNSELECT_VARIANT/ERROR',
};

export const POS_VARIANT_LOADED = {
    success: 'POS/VARIANT_LOADED/SUCCESS',
};

/* POS CART */
export const POS_GET_CART = {
    loading: 'POS/GET_CART/LOADING',
    success: 'POS/GET_CART/SUCCESS',
    error: 'POS/GET_CART/ERROR',
};

export const POS_ADD_TO_CART = {
    loading: 'POS/ADD_TO_CART/LOADING',
    success: 'POS/ADD_TO_CART/SUCCESS',
    error: 'POS/ADD_TO_CART/ERROR',
};

export const POS_REMOVE_FROM_CART = {
    loading: 'POS/REMOVE_FROM_CART/LOADING',
    success: 'POS/REMOVE_FROM_CART/SUCCESS',
    error: 'POS/REMOVE_FROM_CART/ERROR',
};

export const POS_CLEAR_CART = {
    loading: 'POS/CLEAR_CART/LOADING',
    success: 'POS/CLEAR_CART/SUCCESS',
    error: 'POS/CLEAR_CART/ERROR',
};

export const POS_UPDATE_CART_AUTOFOCUS = 'POS/UPDATE_CART_AUTOFOCUS';
export const POS_UNSELECT_CART = 'POS/UNSELECT_CART';

/* POS CLIENT*/
export const POS_SELECT_CLIENT = {
    loading: 'POS/SELECT_CLIENT/LOADING',
    success: 'POS/SELECT_CLIENT/SUCCESS',
    error: 'POS/SELECT_CLIENT/ERROR',
};

export const POS_UNSELECT_CLIENT = 'POS/UNSELECT_CLIENT';

export const POS_SET_CLIENT = {
    loading: 'POS/SET_CLIENT/LOADING',
    success: 'POS/SET_CLIENT/SUCCESS',
    error: 'POS/SET_CLIENT/ERROR',
};

export const POS_SET_CLIENT_NETWORK = 'POS/SET_CLIENT_NETWORK';

export const POS_UPDATE_CLIENT = {
    loading: 'POS/UPDATE_CLIENT/LOADING',
    success: 'POS/UPDATE_CLIENT/SUCCESS',
    error: 'POS/UPDATE_CLIENT/ERROR',
};

export const POS_CREATE_CLIENT = {
    loading: 'POS/CREATE_CLIENT/LOADING',
    success: 'POS/CREATE_CLIENT/SUCCESS',
    error: 'POS/CREATE_CLIENT/ERROR',
};

/* POS PAYMENT */

export const POS_ADD_PAYMENT = {
    loading: 'POS/ADD_PAYMENT/LOADING',
    success: 'POS/ADD_PAYMENT/SUCCESS',
    error: 'POS/ADD_PAYMENT/ERROR',
};

export const POS_REMOVE_PAYMENT = {
    loading: 'POS/REMOVE_PAYMENT/LOADING',
    success: 'POS/REMOVE_PAYMENT/SUCCESS',
    error: 'POS/REMOVE_PAYMENT/ERROR',
};

export const POS_SET_PAYMENTMETHODSELECTED = 'POS_SET_PAYMENTMETHODSELECTED';
export const POS_SET_PAYMENTCREDITMETHODSELECTED = 'POS_SET_PAYMENTCREDITMETHODSELECTED';
export const POS_GET_PAYMENT_METHODS = 'POS/GET_PAYMENT_METHODS';
export const POS_CLEAR_PAYMENTS = 'POS/CLEAR_PAYMENTS';

// EXPORT DATA
export const EXPORT_COMISSIONS = 'EXPORT_COMISSIONS';
