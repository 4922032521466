import axios from 'axios';
import {
  API_BASE_URL,
  INDEX_ADMIN_CANDIDATE,
  INDEX_ADMIN_DISTRIBUTOR,
  REGISTER_DOCUMENT_ADMIN_CANDIDATE,
  CLEAR_REGISTER_DOCUMENT_ADMIN_CANDIDATE,
  ADMIN_UPDATE_CANDIDATE,
  ADMIN_REJECT_CANDIDATE,
  ADMIN_ACCEPT_CANDIDATE,
  UPDATE_ADMIN_DISTRIBUTOR,
} from './types';
import { dispatchAction } from 'utils/actions/common';

export const indexAdminCandidates = () => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/candidates.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      let candidates = {data: JSON.parse(res.data.candidates || [])}
      return dispatchAction(dispatch, candidates, INDEX_ADMIN_CANDIDATE)
    })
  }
}

export const indexAdminDistributors = () => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/distributors.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      let distributors = {data: JSON.parse(res.data.distributors || [])}
      return dispatchAction(dispatch, distributors, INDEX_ADMIN_DISTRIBUTOR)
    })
  }
}

export const updateAdminDistributor = ({distributor, id}) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'PATCH',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/distributors/${id}`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      params: distributor,
      json: true
    }).then(res => {
      let distributor = {data: res.data.distributor || []}
      dispatchAction(dispatch, distributor, UPDATE_ADMIN_DISTRIBUTOR)
      return distributor
    })
  }
}

export const adminFetchPlans = () => {
  
}

export const adminDistributorFetchSummary = (props) => {
  return fetchDistributorData(props, "summary")
}

export const adminDistributorFetchCredit = (props) => {
  return fetchDistributorData(props, "credit")
}

export const adminDistributorFetchSubscription = (props) => {
  return fetchDistributorData(props, "subscription")
}

export const adminDistributorFetchNetwork = (props) => {
  return fetchDistributorData(props, "network");
}

const parse_obj = (obj) => {
  let keys = Object.keys(obj.data);
  keys.forEach((key) => {
    if (typeof obj["data"][key] === "string") {
      obj["data"][key] = JSON.parse(obj["data"][key]);
    }
  })
  return obj;
}

const fetchDistributorData = (props, type) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/distributors/fetch_${type}.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      params: {id: props.id},
      json: true
    }).then(res => {
      let res_obj = {data: {id: props.id, ...JSON.parse(res.data[type] || [])}}
      let parsed_obj = parse_obj(res_obj);
      return dispatchAction(dispatch, parsed_obj, UPDATE_ADMIN_DISTRIBUTOR)
    })
  }
}

export const adminCandidateMessage = (user_id, message) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'post',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/candidates/send_email.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {user_id, message},
      json: true
    })
  }
}

export const registerDocumentAdminCandidate = (userId) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/candidates/send_candidate_register_document.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      params: {user_id: userId},
      json: true
    }).then(res => {
      let register_document = {data: JSON.parse(res.data.register_document || [])}
      return dispatchAction(dispatch, register_document, REGISTER_DOCUMENT_ADMIN_CANDIDATE)
    })
  }
}

export const retireveUserMembershipData = (user_id, user_role) => {
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/membership/${user_id}`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      params: {user_id}
    }).then((res) => {
      let membership = JSON.parse(res.data.membership);
      if (user_role === "candidate") {
        dispatch({type: ADMIN_UPDATE_CANDIDATE, payload: {id: user_id, membership}})
      } else if (user_role === "distributor") {
        dispatch({type: UPDATE_ADMIN_DISTRIBUTOR, payload: {id: user_id, membership}})
      }
      return res;
    }).catch(err => {
      return err.response
      //throw alert
      //message: err.response.data.error.message
    })
  }
}

export const clearSelectedRegisterDocumentAdminCandidate = (userId) => {
  return dispatch => {
    dispatch({type: CLEAR_REGISTER_DOCUMENT_ADMIN_CANDIDATE})
  }
}

export const adminUpdateCandidate = (user) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'post',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/candidates/update_candidate.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {user, user_id: user.id},
      json: true
    }).then(res => {
      let data = {data: JSON.parse(res.data.user || [])}
      return dispatchAction(dispatch, data, ADMIN_UPDATE_CANDIDATE)
    })
  }
}

export const adminCandidateAccept = (data) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'post',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/candidates/approve.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {...data},
      json: true
    }).then(res => {
      let data = {data: JSON.parse(res.data.user || [])}
      if(data.data.role === "distributor_inactive" || data.data.role === "distributor" ) {
        dispatchAction(dispatch, data, ADMIN_ACCEPT_CANDIDATE);
        return data.data;
      }
    })
  }
}

export const adminCandidateReject = (data) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({      
      method: 'post',
      url: API_BASE_URL+`/es/app/api/v1/users/admin/candidates/reject.js`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: data,
      json: true
    }).then(res => {
      let data = {data: JSON.parse(res.data.user || [])}
      if (data.data.role === "rejected_candidate") {
        return dispatchAction(dispatch, data, ADMIN_REJECT_CANDIDATE)
      } else {
        //error
      }
    })
  }
}
